import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import server from '../../config.json';


const Customer = () => {

    let navigate = useNavigate();

    const [user, setUser] = useState({
        firstname: "",
        lastname: "",
        email: "",
        password: ""
    });

    let Name, value;
    const handleInputes = (e) => {
        Name = e.target.name;
        value = e.target.value;
        setUser({ ...user, [Name]: value })
    }

    const PostData = async (e) => {
        e.preventDefault();

        const getDivfN = document.getElementById("fnameErr");
        const getSpanfN = document.getElementById("fnameErrSpan");
        getDivfN.className = "";
        getSpanfN.className = "";
        getSpanfN.innerHTML = "";
        const getDivlN = document.getElementById("lnameErr");
        const getSpanlN = document.getElementById("lnameErrSpan");
        getDivlN.className = "";
        getSpanlN.className = "";
        getSpanlN.innerHTML = "";
        const getDivE = document.getElementById("emailErr");
        const getSpanE = document.getElementById("emailErrSpan");
        getDivE.className = "";
        getSpanE.className = "";
        getSpanE.innerHTML = "";
        const getDivP = document.getElementById("passErr");
        const getSpanP = document.getElementById("passErrspan");
        getDivP.className = "";
        getSpanP.className = "";
        getSpanP.innerHTML = "";
        const register = document.getElementById("register");
        const registerSpan = document.getElementById("registerSpan");


        if (!user) {
            getDivfN.className = "alert alert-danger rmv";
            getSpanfN.className = "alert-text rmv";
            getSpanfN.innerHTML = "Please Enter valid First Name";
            getDivlN.className = "alert alert-danger rmv";
            getSpanlN.className = "alert-text rmv";
            getSpanlN.innerHTML = "Please Enter valid Last Name";
            getDivE.className = "alert alert-danger rmv";
            getSpanE.className = "alert-text rmv";
            getSpanE.innerHTML = "Please Enter Valid Email";
            getDivP.className = "alert alert-danger rmv";
            getSpanP.className = "alert-text rmv ";
            getSpanP.innerHTML = `The password must contain at least three character categories among the following:Uppercase characters (A-Z)Lowercase characters (a-z)Digits (0-9)Special characters like @&%$#*`;


        }
        else if (!user.firstname.match(/[A-Z]/ig)) {
            getDivfN.className = "alert alert-danger rmv";
            getSpanfN.className = "alert-text rmv";
            getSpanfN.innerHTML = "Please Enter valid User Name";

        } else if (!user.lastname.match(/[A-Z]/ig)) {
            getDivlN.className = "alert alert-danger rmv";
            getSpanlN.className = "alert-text rmv";
            getSpanlN.innerHTML = "Please Enter valid User Name";

        } else if (!user.email.match(/^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
            console.log("please give");
            getDivE.className = "alert alert-danger rmv";
            getSpanE.className = "alert-text rmv";
            getSpanE.innerHTML = "Please Enter Valid Email";

        } else if (!user.password.match(/^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/)) {
            getDivP.className = "alert alert-danger rmv";
            getSpanP.className = "alert-text rmv ";
            getSpanP.innerHTML = `The password must contain at least three character categories among the following:Uppercase characters (A-Z)Lowercase characters (a-z)Digits (0-9)Special characters like @&%$#*`;
        } else {
            axios.post(`${server.api}customerregister`, user)
                .then((res) => {
                    if (res.data.message == "Failed! Email is already in use!") {
                        getDivE.className = "alert alert-danger rmv";
                        getSpanE.className = "alert-text rmv";
                        getSpanE.innerHTML = "Failed! Email is already in use!";
                    } else {
                        console.log(res.data)
                        register.className = "alert alert-success ";
                        registerSpan.className = "alert-text ";
                        registerSpan.innerHTML = res.data.message;
                        const myTimeout = setTimeout(Registration, 2000);

                        function Registration() {
                            navigate('/login ');
                        }

                    }
                }).catch((error) => {
                    console.log(error);
                });
        }



    }



    return (
        <>
            <div className="container mt--8 pb-5">
                {/* Table */}
                <div className="row justify-content-center">
                    <div className="col-lg-6 col-md-8">
                        <div className="card bg-secondary border-0">
                            {/* <div className="card-header bg-transparent pb-5">
                                        <div className="text-muted text-center mt-2 mb-4"><small>Sign up with</small></div>
                                        <div className="text-center">
                                            <a href="#" className="btn btn-neutral btn-icon mr-4">
                                                <span className="btn-inner--icon"><img src="../assets/img/icons/common/github.svg" /></span>
                                                <span className="btn-inner--text">Github</span>
                                            </a>
                                            <a href="#" className="btn btn-neutral btn-icon">
                                                <span className="btn-inner--icon"><img src="../assets/img/icons/common/google.svg" /></span>
                                                <span className="btn-inner--text">Google</span>
                                            </a>
                                        </div>
                                    </div> */}
                            <div className="card-body px-lg-5 py-lg-5">
                                <div className="text-center text-muted mb-4">
                                    <div id="register" role="alert">
                                        <span class="" id='registerSpan'><strong></strong></span>
                                    </div>
                                    <h3 className="text-black">Customer Sign Up</h3>
                                    <small></small>
                                </div>
                                <form role="form" method='POST'>
                                    <div className="form-group">
                                        <div className="input-group input-group-merge input-group-alternative mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i class="fas fa-user"></i></span>
                                            </div>
                                            <input name='firstname' id='name' className="form-control" placeholder="First Name" type="text" value={user.firstname} onChange={handleInputes} />
                                        </div>
                                        <div id='fnameErr' role="alert">
                                            <span class="" id='fnameErrSpan'><strong></strong></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group input-group-merge input-group-alternative mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i class="fas fa-user"></i></span>
                                            </div>
                                            <input name='lastname' id='name' className="form-control" placeholder="Last Name" type="text" value={user.lastname} onChange={handleInputes} />
                                        </div>
                                        <div id='lnameErr' role="alert">
                                            <span class="" id='lnameErrSpan'><strong></strong></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group input-group-merge input-group-alternative mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="ni ni-email-83" /></span>
                                            </div>
                                            <input name='email' id='email' className="form-control" placeholder="Email" type="email" value={user.email} onChange={handleInputes} />
                                        </div>
                                        <div class="" id='emailErr' role="alert">

                                            <span class="" id="emailErrSpan"><strong></strong></span>
                                        </div>
                                    </div>
                                    <div className="form-group">
                                        <div className="input-group input-group-merge input-group-alternative">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="ni ni-lock-circle-open" /></span>
                                            </div>
                                            <input name='password' id='pass' className="form-control" placeholder="Password" type="password" value={user.password} onChange={handleInputes} />
                                        </div>
                                        <div id="passErr" role="alert">
                                            <span class="" id='passErrspan'><strong></strong></span>
                                        </div>
                                    </div>
                                    <div className="text-muted font-italic"><small>password strength: <span className="text-success font-weight-700">The password must contain at least three character categories among the following:Uppercase characters (A-Z)Lowercase characters (a-z)Digits (0-9)Special characters like @&%$#*</span></small></div>
                                    {/* <div className="row my-4">
                                                <div className="col-12">
                                                    <div className="custom-control custom-control-alternative custom-checkbox">
                                                        <input name='agree' className="custom-control-input" id="customCheckRegister" value={user.agree} onChange={handleInputes} type="checkbox" />
                                                        <label className="custom-control-label" htmlFor="customCheckRegister">
                                                            <span className="text-muted">I agree with the <a href="#!">Privacy Policy</a></span>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div> */}
                                    <div className="text-center">
                                        <button type="button" onClick={PostData} id='popup' className="btn btn-primary mt-4">Create account</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="row " style={{ marginTop: "-1.75rem" }}>
                            <div className="col-6">
                                {/* <a href="/forgotpassword" className="text-light"><small>Forgot password?</small></a> */}
                            </div>
                            <div className="col-6 text-right">
                                {/* <h4>Wallet Credentials</h4> */}
                                <a href="/login" className="text-light"><h5>Already Have account ? Sign in</h5></a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Customer;