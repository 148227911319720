import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import server from '../../config.json';





function Walletpass() {

    const [Error, geterror] = useState([{}]);

    const [pass, getpass] = useState({
        password: ""
    });

    const verifyPass = () => {

        const getDivP = document.getElementById("ApiErr");
        const getSpanP = document.getElementById("ApiErrspan");
        getDivP.className = "";
        getSpanP.className = "";
        getSpanP.innerHTML = "";

        if (!pass.password) {

            getDivP.className = "alert alert-danger rmv";
            getSpanP.className = "alert-text rmv";
            getSpanP.innerHTML = "Please Enter Password";

        } else {
            const tk = localStorage.getItem('jwt');
            const headers = {
                "Authorization": tk
            }

            getDivP.className = "alert alert-success rmv";
            getSpanP.className = "alert-text rmv";
            getSpanP.innerHTML = "Sending.....";

            axios.post(`${server.api}sendtran`, pass, { headers })
                .then((res) => {
                    console.log("AmMAMMA", res);

                    if (res.data === "Transaction Done") {
                        getDivP.className = "alert alert-success rmv";
                        getSpanP.className = "alert-text rmv";
                        getSpanP.innerHTML = res.data;
                        console.log(res.data);

                        

                        
                        navigate('/wallet');
                    }
                    else {

                        getDivP.className = "alert alert-danger rmv";
                        getSpanP.className = "alert-text rmv";
                        getSpanP.innerHTML = res.data;
                        console.log(Error);

                    }


                }).catch((error) => {
                    console.log(error)
                    console.log('aman');
                    navigate('/login');
                })


        }

    }


    let Names, Value;

    const getpassval = (e) => {
        Names = e.target.name;
        Value = e.target.value;
        getpass({ ...pass, [Names]: Value });
    }







    const [userinfo, getinfo] = useState([{

        name: "",
        email: "",
        firstName: "",
        lastName: "",
        address: "",
        phoneNumber: "",
        city: "",
        country: "",
        postalCode: "",
        ProfileImage: "",


    }]);











    let navigate = useNavigate();

    useEffect(() => {
        callWalletpass();
    }, [])

    const logout = () => {
        localStorage.removeItem('jwt');
        navigate('/login');
    }

    const callWalletpass = async () => {

        // const getDivN = document.getElementById("nameErr");
        // const getSpanN = document.getElementById("nameErrSpan");
        // const getUploadInfo = document.getElementById("updateInfo");
        // const getUploadInfoSpan = document.getElementById("updateInfoSpan");
        // getDivN.className = "";
        // getSpanN.className = "";
        // getSpanN.innerHTML = "";
        // getUploadInfo.className = "";
        // getUploadInfoSpan.className = "";
        // getUploadInfoSpan.innerHTML = "";


        try {
            const tk = localStorage.getItem('jwt');
            const headers = {
                "Authorization": tk
            }
            await axios.get(`${server.api}checkUser`, { headers })
                .then((res) => {

                    console.log(res)
                    getinfo(res.data);
                    console.log(res.data);


                }).catch((error) => {
                    console.log(error)
                    localStorage.removeItem('jwt');
                    navigate('/login');
                })

            await axios.get(`${server.api}checktransign`, { headers })
                .then((res) => {

                    console.log(res.data)

                    if (res.data == false) {
                        navigate('/wallet');
                    }


                }).catch((error) => {
                    console.log(error)
                    localStorage.removeItem('jwt');
                    navigate('/login');
                })






        } catch (error) {

            navigate('/login');

        }
    }

    return (
        <>
            <div>
                <nav className="sidenav navbar navbar-vertical  fixed-left  navbar-expand-xs navbar-light bg-white" id="sidenav-main">
                    <div className="scrollbar-inner">
                        {/* Brand */}
                        <div className="sidenav-header  align-items-center">
                            <a className="navbar-brand" href="">
                                <img src="../assets/img/brand/logo-3.png" />
                                <h5 className='h2 text-default d-inline-block mb-0 ml-2 mt-1'>Quick All MVP</h5>
                            </a>
                        </div>
                        <div className="navbar-inner">
                            {/* Collapse */}
                            <div className="collapse navbar-collapse" id="sidenav-collapse-main">
                                {/* Nav items */}
                                <ul className="navbar-nav">
                                    <li className="nav-item">
                                        <Link className="nav-link active" to={userinfo.Role == "customer" ? ("/customerdashboard") : ("/Ownerdashboard")}>
                                            <i className="ni ni-tv-2 text-primary" />
                                            <span className="nav-link-text">Dashboard</span>
                                        </Link>
                                    </li>
                                    {/* <li className="nav-item">
                <Link className="nav-link" to="/icons">
                  <i className="ni ni-planet text-orange" />
                  <span className="nav-link-text">Icons</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/map">
                  <i className="ni ni-pin-3 text-primary" />
                  <span className="nav-link-text">Google</span>
                </Link>
              </li> */}
                                    <li className="nav-item">
                                        <Link className="nav-link" to="/profile">
                                            <i className="ni ni-single-02 text-yellow" />
                                            <span className="nav-link-text">Profile</span>
                                        </Link>
                                    </li>
                                    <li className="nav-item">
                                        <Link className="nav-link active" to="/createwallet">
                                            <i className="ni ni-tv-2 text-primary" />
                                            <span className="nav-link-text">Wallet</span>
                                        </Link>
                                    </li>
                                    {/* <li className="nav-item">
                      <Link className="nav-link" to="/tables">
                        <i className="ni ni-bullet-list-67 text-default" />
                        <span className="nav-link-text">Tables</span>
                      </Link>
                    </li> */}
                                    {/* <li className="nav-item">
                <Link className="nav-link" to="/login">
                  <i className="ni ni-key-25 text-info" />
                  <span className="nav-link-text">Login</span>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/register">
                  <i className="ni ni-circle-08 text-pink" />
                  <span className="nav-link-text">Register</span>
                </Link>
              </li> */}
              <li className="nav-item">
                  {userinfo.Role == "customer" ? (
                    <Link className="nav-link" to="/tables">
                      <i className="ni ni-bullet-list-67 text-default" />
                      <span className="nav-link-text">Tables</span>
                    </Link>

                  ) : (<Link className="nav-link" to="/services">
                    <i className="ni ni-bullet-list-67 text-default" />
                    <span className="nav-link-text">Create Service</span>
                  </Link>)}
                </li>
                <li className="nav-item">
                  {userinfo.Role == "customer" ? (
                    <Link className="nav-link" to="/tables">
                      <i className="ni ni-bullet-list-67 text-default" />
                      <span className="nav-link-text">Tables</span>
                    </Link>

                  ) : (<Link className="nav-link" to="/serviceslist">
                    <i className="ni ni-bullet-list-67 text-default" />
                    <span className="nav-link-text">Services List</span>
                  </Link>)}
                </li>
                                </ul>
                                {/* Divider */}
                                <hr className="my-3" />
                                {/* Heading */}

                            </div>
                        </div>
                    </div>
                </nav>
                {/* Main content */}
                <div className="main-content" id="panel">
                    {/* Topnav */}
                    <nav className="navbar navbar-top navbar-expand navbar-dark bg-default border-bottom">
                        <div className="container-fluid">
                            <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                {/* Search form */}
                                <form className="navbar-search navbar-search-light form-inline mr-sm-3" id="navbar-search-main">
                                    <div className="form-group mb-0">
                                        <div className="input-group input-group-alternative input-group-merge">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text"><i className="fas fa-search" /></span>
                                            </div>
                                            <input className="form-control" placeholder="Search" type="text" />
                                        </div>
                                    </div>
                                    <button type="button" className="close" data-action="search-close" data-target="#navbar-search-main" aria-label="Close">
                                        <span aria-hidden="true">×</span>
                                    </button>
                                </form>
                                {/* Navbar links */}
                                <ul className="navbar-nav align-items-center ml-md-auto ">
                                    <li className="nav-item d-xl-none">
                                        {/* Sidenav toggler */}
                                        <div className="pr-3 sidenav-toggler sidenav-toggler-dark" data-action="sidenav-pin" data-target="#sidenav-main">
                                            <div className="sidenav-toggler-inner">
                                                <i className="sidenav-toggler-line" />
                                                <i className="sidenav-toggler-line" />
                                                <i className="sidenav-toggler-line" />
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                                <ul className="navbar-nav align-items-center  ml-auto ml-md-0 ">
                                    <li className="nav-item dropdown">
                                        <a className="nav-link pr-0" href="#" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                            <div className="media align-items-center">
                                                <span className="avatar avatar-sm rounded-circle">
                                                    {!userinfo.ProfileImage ? (
                                                        <i className="fas fa-upload"></i>
                                                    ) : (
                                                        <img src={userinfo.ProfileImage} />
                                                    )

                                                    }
                                                </span>
                                                <div className="media-body  ml-2  d-none d-lg-block">
                                                <span className="mb-0 text-sm  font-weight-bold">{`${userinfo.FirstName} ${userinfo.LastName}`}</span>
                                                </div>
                                            </div>
                                        </a>
                                        <div className="dropdown-menu  dropdown-menu-right ">
                                            <div className="dropdown-header noti-title">
                                                <h6 className="text-overflow m-0">Welcome!</h6>
                                            </div>
                                            <a href="/profile" className="dropdown-item">
                                                <i className="ni ni-single-02" />
                                                <span>My profile</span>
                                            </a>
                                            <a href="#!" className="dropdown-item">
                                                <i className="ni ni-settings-gear-65" />
                                                <span>Settings</span>
                                            </a>
                                            <a href="#!" className="dropdown-item">
                                                <i className="ni ni-calendar-grid-58" />
                                                <span>Activity</span>
                                            </a>
                                            <a href="#!" className="dropdown-item">
                                                <i className="ni ni-support-16" />
                                                <span>Support</span>
                                            </a>
                                            <div className="dropdown-divider" />
                                            <a href="" onClick={logout} className="dropdown-item">
                                                <i className="ni ni-user-run" />
                                                <span>Logout</span>
                                            </a>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </nav>
                    {/* Header */}
                    {/* Header */}
                    <div className="main-content">
                        {/* Header */}
                        <div className="header bg-gradient-primary py-7 py-lg-6 pt-lg-4">
                            <div className="container">
                                <div className="header-body text-center mb-7">
                                    <div className="row justify-content-center">
                                        <div className="col-xl-5 col-lg-6 col-md-8 px-5">
                                            <h1 className="text-white"></h1>

                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="separator separator-bottom separator-skew zindex-100">
                                <svg x={0} y={0} viewBox="0 0 2560 100" preserveAspectRatio="none" version="1.1" xmlns="http://www.w3.org/2000/svg">
                                    <polygon className="fill-default" points="2560 0 2560 100 0 100" />
                                </svg>
                            </div>
                        </div>
                        {/* Page content */}
                        <div className="container mt--8 pb-5">
                            <div className="row justify-content-center">
                                <div className="col-lg-5 col-md-7">
                                    <div className="card bg-secondary border-0 mb-0">
                                        <div className="card-header bg-transparent pb-5">

                                        </div>
                                        <div className="card-body px-lg-5 py-lg-5">
                                            <div id="ApiErr" role="alert">
                                                <span class="" id='ApiErrspan'><strong></strong></span>
                                            </div>
                                            <div className="text-center text-muted mb-4">
                                                <h3 className="text-black">Wallet credentials</h3>
                                                <small></small>
                                            </div>
                                            <form role="form">
                                                <div className="form-group">
                                                    <div className="input-group input-group-merge input-group-alternative">
                                                        <div className="input-group-prepend">
                                                            <span className="input-group-text"><i className="ni ni-lock-circle-open" /></span>
                                                        </div>
                                                        <input className="form-control" onChange={getpassval} value={pass.password} placeholder="Password" type="password" name='password' />
                                                    </div>
                                                </div>
                                                <div id="passErr" role="alert">
                                                    <span class="" id='passErrspan'><strong></strong></span>
                                                </div>

                                                <div className="text-center">
                                                    <button type="button" onClick={verifyPass} className="btn btn-primary my-4">Send</button>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                    <div className="row mt-3">
                                        <div className="col-6">
                                            {/* <a href="/forgotpassword" className="text-light"><h5>Forgot password?</h5></a> */}
                                        </div>
                                        <div className="col-6 text-right">
                                            <a href="/forgotwalletpass" className="text-light"><h5>Forgot password?</h5></a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>


    )

}

export default Walletpass;