import React from 'react';
import CustomerDash from './Components/Dashboard/Customerdash';
import Login from './Components/Login/Login';
import Profile from './Components/Profile/Profile';
import Register from './Components/Register/Register';
import { Routes, Route } from 'react-router-dom';
import ForgotPass from './Components/ForgotPassword/ForgotPassword';
import ForgotPassConfirm from './Components/ForgotPassword/forgotPassConfirm';
import Carserviceowner from './Components/Dashboard/Carserviceownerdash'
import CreateWallet from './Components/wallet/CreateWallet';
import Wallet from './Components/wallet/Wallet';
import CheckWalletpass from './Components/wallet/Checkwalletpass';
import Showseed from './Components/wallet/Showseed';
import ForgotWalletPass from './Components/wallet/Forgotwalletpass'
import Ownerservice from './Components/Service Page/Ownerservice';
import Serviceslist from './Components/ListServices/ServicesList';





const App = () => {

  return (
    <>
    
      <Routes>      
         <Route exact path="/" element={<Register/>}/> 
         <Route exact path="/login" element={<Login/>}/>        
         <Route exact path="/profile" element={<Profile/>}/>             
         <Route exact path="/customerdashboard" element={<CustomerDash/>}/>         
         <Route exact path="/forgotpassword" element={<ForgotPass/>}/>         
         <Route exact path="/forgotpassconfirm" element={<ForgotPassConfirm/>}/>
         <Route exact path="/Ownerdashboard" element={<Carserviceowner/>}/>
         <Route exact path="/createwallet" element={<CreateWallet/>}/>  
         <Route exact path="/wallet" element={<Wallet/>}/>  
         <Route exact path="/verifypass" element={<CheckWalletpass/>}/>  
         <Route exact path="/getseed" element={<Showseed/>}/>
         <Route exact path="/forgotwalletpass" element={<ForgotWalletPass/>}/>
         <Route exact path="/services" element={<Ownerservice/>}/>     
         <Route exact path="/serviceslist" element={<Serviceslist/>}/>                     
      </Routes>
    </>

  )
};

export default App;
